<script setup lang="ts">
import { __ } from '@@/bits/intl'
import { ANONYMOUS_AVATAR } from '@@/bits/user_model'
import { processedUrl } from '@padlet/vivaldi-client'
import { computed, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    /**
     * width of the avatar
     */
    width?: number
    /**
     * height of the avatar
     */
    height?: number
    /**
     * Image source URL
     */
    src?: string | null
    /**
     * Fallback image source URL
     */
    fallbackSrc?: string
    /**
     * Name of account to display for alt text.
     */
    altTextName?: string
    /**
     * Alternative text for overriding the default alt text.
     */
    alt?: string
  }>(),
  {
    width: undefined,
    height: undefined,
    src: null,
    fallbackSrc: ANONYMOUS_AVATAR,
    altTextName: 'anonymous',
    alt: undefined,
  },
)

const isErrored = ref(false)

const processedSrc = computed(() => {
  if (!props.src || isErrored.value) return props.fallbackSrc

  // for relative urls e.g. when developing locally
  if (props.src.startsWith('/')) return props.src

  return processedUrl(props.src, transforms.value)
})

const altText = computed(
  () => props.alt ?? (props.altTextName ? __('Avatar of %{name}', { name: props.altTextName }) : ''),
)

const transforms = computed(() => {
  if (props.width && props.height) {
    return {
      width: props.width,
      height: props.height,
      preset: 'avatar',
    }
  }
  return {
    preset: 'avatar',
  }
})
</script>

<script lang="ts">
export default {}
</script>

<template>
  <img :width="width" :height="height" :alt="altText" :src="processedSrc" @error="isErrored = true" />
</template>
